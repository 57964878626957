<template>
  <span v-if="isCreateForm">
    <el-button type="primary" :icon="PlusIcon" @click="handleOpen"
      >Ajouter</el-button
    >
  </span>
  <span v-else>
    <el-button :icon="EditIcon" size="small" @click="handleOpen"></el-button>
  </span>

  <el-dialog
    v-model="dialogVisible"
    title="Formulaire"
    width="35%"
    append-to-body
  >
    <el-form ref="reportForm" :model="form" :rules="rules" label-position="top">
      <el-form-item label="Titre " prop="title">
        <el-input v-model="form.title" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="Nom " prop="name">
        <el-input v-model="form.name" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="Région " prop="region">
        <el-select
          v-model="form.region"
          placeholder="Sélectionner"
          style="width: 100%"
        >
          <el-option
            v-for="(item, index) in REGION_OPTIONS"
            :key="index"
            :label="item.label"
            :value="item.value"
            clearable
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Catégorie " prop="categoryId">
        <el-select
          v-model="form.categoryId"
          placeholder="Sélectionner"
          style="width: 100%"
        >
          <el-option
            v-for="(item, index) in categoryList"
            :key="index"
            :label="item.name"
            :value="item.id"
            clearable
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Ordre " prop="ordre">
        <el-input v-model="form.showOrder" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="Activé " prop="enabled">
        <el-switch v-model="form.enabled" />
      </el-form-item>
      <el-form-item label="Description " prop="description">
        <el-input
          v-model="form.description"
          autocomplete="off"
          type="textarea"
        ></el-input>
      </el-form-item>
      <el-form-item label="Ressource " prop="resource">
        <el-input
          v-model="form.resource"
          autocomplete="off"
          type="textarea"
        ></el-input>
      </el-form-item>
      <el-form-item label="Parametres " prop="params">
        <el-input
          v-model="form.params"
          autocomplete="off"
          type="textarea"
        ></el-input>
      </el-form-item>
      <el-form-item label="Parametres de requête" prop="queryParams">
        <el-input
          v-model="form.queryParams"
          autocomplete="off"
          type="textarea"
        ></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">Annuler</el-button>
        <el-button
          v-if="isCreateForm"
          type="primary"
          @click="create('reportForm')"
          >Confirmer</el-button
        >
        <el-button v-else type="primary" @click="update('reportForm')"
          >Confirmer</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { REGION_OPTIONS } from '../../helpers/StaticData'

export default {
  props: {
    action: {
      type: String,
      default: 'create',
    },
    row: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      REGION_OPTIONS,
      dialogVisible: false,
      formLabelWidth: '150px',
      form: {
        title: null,
        name: null,
        description: null,
        categoryId: null,
        showOrder: null,
        enabled: false,
        resource: null,
        params: null,
        queryParams: null,
      },
      confirmPassword: null,
      rules: {
        title: [
          {
            required: true,
            message: 'Veuillez saisir le titre',
            trigger: 'change',
          },
        ],
        name: [
          {
            required: true,
            message: 'Veuillez saisir le nom',
            trigger: 'change',
          },
        ],
        categoryId: [
          {
            required: true,
            message: 'Veuillez sélectionner la catégorie',
            trigger: 'change',
          },
        ],
        resource: [
          {
            required: false,
            message: 'Veuillez saisir la classe',
            trigger: 'change',
          },
        ],
      },
    }
  },
  computed: {
    ...mapState('categories', {
      categoryList: 'list',
    }),
    ...mapGetters({
      reportClasses: 'reports/reportClasses',
    }),
    isCreateForm() {
      if (this.action == 'create') {
        return true
      } else return false
    },
  },
  mounted() {},
  methods: {
    handleOpen() {
      this.$store.dispatch('categories/getList', {})
      this.dialogVisible = true
      if (this.action == 'edit') this.populate(this.row)
    },
    populate(row) {
      this.form.title = row.title
      this.form.name = row.name
      this.form.description = row.description
      this.form.categoryId = row.categoryId
      this.form.showOrder = row.showOrder
      this.form.enabled = row.enabled
      this.form.region = row.region
      this.form.resource =
        row.resource != null ? JSON.stringify(row.resource) : ''
      this.form.params = row.params != null ? JSON.stringify(row.params) : ''
      this.form.queryParams =
        row.queryParams != null ? JSON.stringify(row.queryParams) : ''
    },
    create(reportForm) {
      this.$refs[reportForm].validate((valid) => {
        if (valid) {
          let payload = {
            data: {
              ...this.form,
            },
          }
          this.$store
            .dispatch('reports/addItem', payload)
            .then(() => {
              this.dialogVisible = false
            })
            .then(() => {
              this.form.title = ''
              this.form.name = ''
              this.form.description = ''
              this.form.categoryId = ''
              this.form.region = ''
              this.form.resource = ''
              this.form.params = ''
              this.form.queryParams = ''
              this.form.showOrder = ''
              this.form.enabled = ''
            })
        } else return false
      })
    },
    update(reportForm) {
      this.$refs[reportForm].validate((valid) => {
        if (valid) {
          let payload = {
            id: this.row.id,
            data: {
              ...this.form,
            },
          }
          this.$store.dispatch('reports/updateItem', payload).then(() => {
            this.dialogVisible = false
          })
        } else return false
      })
    },
  },
}
</script>

<style></style>
