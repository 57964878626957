<template>
  <el-container direction="vertical">
    <el-row justify="space-between" align="middle">
      <el-col :span="4"><h3>Rapports </h3></el-col>
      <el-col :span="6">
        <el-space alignment="flex-end">
          <el-input v-model="search" placeholder="Type to search" />

          <report-dialog :action="'create'" />
        </el-space>
      </el-col>
    </el-row>
    <el-table :data="list" style="width: 100%">
      <el-table-column label="Titre" prop="title" />
      <el-table-column label="Description" prop="description" />
      <el-table-column label="Categorie" prop="category.name" />
      <el-table-column label="Ordre" prop="showOrder" />
      <el-table-column label="Activé" prop="enabled" />
      <el-table-column label="Action" align="right" width="100" >
        <template #default="scope">
          <el-space>
            <report-dialog :action="'edit'" :row="scope.row"></report-dialog>
            <el-popconfirm
              confirmButtonText="Oui"
              cancelButtonText="Annuler"
              @confirm="handleDelete(scope.row)"
              :icon="InfoIcon"
              iconColor="#F56C6C"
              title="Voulez vous vraiment supprimer ce boitier ?"
            >
              <template #reference>
                <el-button
                  size="small"
                  type="danger"
                  :icon="DeleteIcon"
                ></el-button>
              </template>
            </el-popconfirm>
          </el-space>
        </template>
      </el-table-column>
    </el-table>
  </el-container>
</template>

<script>
import { mapState } from 'vuex'
import ReportDialog from './Dialog.vue'
export default {
  components: { ReportDialog },
  data() {
    return { search: '' }
  },
  computed: {
    ...mapState('reports', ['list']),
  },
  mounted() {},
  methods: {
    handleDelete(row) {
      this.$store.dispatch('reports/deleteItem', { id: row.id })
    },
  },
}
</script>

<style></style>
