<template>
  <reports-table></reports-table>
</template>

<script>
import ReportsTable from './Table.vue'
export default {
  components: { ReportsTable },
  data() {
    return {}
  },
  mounted() {
    this.$store.dispatch('reports/getList', {})
  },
  methods: {},
}
</script>

<style></style>
